import axios from 'axios';

export const login = () => {};

export const authenticateToken = () => {
  if (typeof window !== 'undefined') {
    return axios({
      method: 'post',
      url: '/api/auth/token',
    }).then((res) => {
      return {
        user: res.data.user,
      };
    });
  }
};
