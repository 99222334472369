import React, { Component } from 'react';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button, FormGroup, Checkbox } from 'react-bootstrap';
import Router from 'next/router';

import { authenticateToken } from '../../actions/auth';
import Head from '../__layouts/Head';

import { getPopups } from '../../actions/popups';
import { AUTH_TYPES, logout } from '../../redux/reducers/auth';

class LayoutContainer extends Component {
  state = {
    loading: true,
    // lastMovedTime: new Date(),
    stopToReadPopup: false,
    popups: [],
  };

  async componentDidMount() {
    // window.setInterval(this.handleCheckMouseAction, 1000 * 60);

    try {
      const auth = await authenticateToken();
      this.props.dispatch({
        type: AUTH_TYPES.LOGIN,
        user: auth.user,
      });

      this.setState({ loading: false });

      if (window.location.pathname === '/') {
        Router.push('/deliveries');
      }
    } catch (e) {
      this.props.dispatch({
        type: AUTH_TYPES.LOGIN,
        user: {},
      });

      this.setState({ loading: false });

      if (window.location.pathname !== '/') {
        Router.push('/');
      }
    }

    const popups = await getPopups();
    this.setState({
      popups,
    });
  }

  // handleCheckMouseAction = () => {
  //   if (differenceInMinutes(new Date(), this.state.lastMovedTime) > 120) {
  //     this.props.logout();
  //   }
  // };

  // handleMouseMove = () => {
  //   this.setState({
  //     lastMovedTime: new Date(),
  //   });
  // };

  closePopup = (p) => {
    const { popups } = this.state;
    if (this.state.stopToReadPopup) {
      this.saveStopToReadPopup(p);
    }

    this.setState({
      popups: popups.slice(0, popups.length - 1),
      stopToReadPopup: false,
    });
  };

  saveStopToReadPopup = (p) => {
    const readPopups = JSON.parse(window.localStorage.getItem('intimequick-read-popup-ids')) || [];

    if (p.id && typeof window !== 'undefined') {
      readPopups.push(p.id);
      window.localStorage.setItem('intimequick-read-popup-ids', JSON.stringify(readPopups));
    }
  };

  handleChange = (e) => {
    const { name, value, checked } = e.target;
    const state = { ...this.state };

    switch (name) {
      case 'stopToReadPopup':
        state[name] = checked;
        break;
      default:
        state[name] = value;
        break;
    }
    this.setState(state);
  };

  render() {
    return (
      <div>
        <Head />
        {this.state.popups.length > 0 &&
          this.state.popups.map((p) => (
            <Modal id="popup" show onHide={() => this.closePopup(p)}>
              <Modal.Body style={{ padding: '0px' }}>
                <img style={{ width: '100%' }} src={p.image || '/static/popup.png'} alt="팝업 공지" />
              </Modal.Body>
              <Modal.Footer>
                <FormGroup style={{ marginBottom: '0px' }}>
                  <Checkbox
                    name="stopToReadPopup"
                    value={this.state.stopToReadPopup}
                    onChange={this.handleChange}
                    style={{ marginRight: '10px' }}
                    inline
                  >
                    다시 보지 않음
                  </Checkbox>
                  <Button onClick={() => this.closePopup(p)}>닫기</Button>
                </FormGroup>
              </Modal.Footer>
            </Modal>
          ))}

        {!this.state.loading && this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

// const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch);

export default connect(mapStateToProps, null)(LayoutContainer);
