import axios from 'axios';

export const getLatestPopup = () => {
  if (typeof window !== 'undefined') {
    const readPopupId = window.localStorage.getItem(
      'intimequick-read-popup-id'
    );

    return axios({
      method: 'get',
      url: '/api/popups/latest',
    }).then((res) => {
      return res.data;
    });
  }
};

export const getPopups = () => {
  if (typeof window !== 'undefined') {
    const readPopups =
      JSON.parse(window.localStorage.getItem('intimequick-read-popup-ids')) ||
      [];

    return axios({
      method: 'get',
      url: '/api/popups',
    }).then((res) => {
      const popups = res.data;
      if (readPopups.length > 0) {
        return popups.filter((p) => {
          return readPopups.indexOf(p.id) < 0;
        });
      }

      return popups;
    });
  }
};
